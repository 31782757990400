
.headerContainer {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 999;

}
.headerEW {
    display: block; 
    height: 50px;
    background-color: #0e2f33;
    position: relative;
}
.headerEWText {
    display: table-cell;
    width: 100%;
    color: white;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    position: relative;
}

@media only screen and (min-width: 400px) {
    .headerEWText{left: -35px;}
}

.logoEW {
    display: table-cell;
    padding-left: 10px;
    padding-right: 10px;
}
