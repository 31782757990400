.body_row {
    padding-top: 20px;
    padding-bottom: 0px;
}

.plain_row {
    background-color: #0e2f33;
    color: white;
}

.body_column {
    max-width: 600px;
    margin:    0px auto;
    padding: 0 10px 0 10px;
  }

.body_content {
    padding: 15px;
    border-top: 1px solid black;

}

.body_content_noline {
    padding: 15px;
}

.content_flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.englishwordsBodyContent {
    text-align: left;
}

.titleEW, h1 {
    font-weight: bold;
    font-size: 1.4em;
    margin: 20px;
    text-align: center;
}

.title2EW, h2 {
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 0px;
    text-align: center;
}

.title3EW, h3 {
    font-weight: bold;
    font-size: 1.0em;
    margin-top: 0px;
    text-align: left;
    margin-block-end: 0.1em;
    margin-block-start: 0em;
}

.line{
    width: 112px;
    border-bottom: 1px solid black;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;

    }
ul {
    margin-block-start: 0em;
    padding-inline-start: 20px;
}


.appsdownload {
    display: flex;
    justify-content: space-around;
}

.appdownload_content {
    display: flex;
    border-radius: 5px;
    padding: 15px;
    margin: 0px 5px 0px 5px;
    border: 1px solid #0e2f33;
    box-shadow: 1px 1px 1px #0e2f33;
    height: 20px;
    font-size: 0.7em;
    align-items: center;
    max-width: 140px;

}

.appdownload_icon {
    margin-right: 10px;
}

.appdownload_text {
    max-width: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 0.9em;

}

.inlinetext {
    display: inline-block;
    max-width: 120px;
    text-align: center;
    font-weight: bold;
    font-size: 0.8em;

}


a:link { color: #0e2f33; text-decoration: none;}
a:visited { color: #0e2f33; text-decoration: none;}
a:hover { color: #0e2f33; text-decoration: none;}
a:active { color: #0e2f33; text-decoration: none;}

.flex_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
}

.flex_divColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.h21_bloc {
    display: flex;
    margin: 5px 25px 5px 5px;
}
.h21_bloc_logo {
    margin: 4px 10px 0 0;
}

@media only screen and (min-width: 1065px) {
    .h21_bloc{max-width: 400px;}
    .body_column{max-width: 1065px;}
}


.alicecarousel {
    padding: 15px;
    text-align: center;
}

.alice-carousel__next-btn {
    text-align: left;
}