.privacyEW {
    align-items: center;
    justify-content: center;
  }

.privacyEWContent {
    color: #0e2f33;
}

.inshort {
    font-weight: bold;
    font-size: 1.4em;
    margin: 20px;
    text-align: center;
    padding-top: 30px;
}

.line{
    width: 112px;
    border-bottom: 1px solid black;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;

    }

.sentence {
    margin-top: 10px;
    text-align: center;
}
    